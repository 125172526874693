<template>
    <div class="order-list">
        <normal-table
            ref="normalTable"
            method="post"
            :tableAction="tableAction"
            :url="url"
            :extra-query="{}"
            hasResetBtn
            :searchForm.sync="form"
            :page-size="200"
            :has-search-form="true"
            @select="select"
            @select-all="selectAll"
            @loaded="loaded"
        >
            <template slot="formItem">
	            <el-form-item label="状态">
		            <el-select v-model="form.status" placeholder="状态" style="width:70px">
			            <el-option label="生效中" value="new"></el-option>
			            <el-option label="已使用" value="active"></el-option>
		            </el-select>
	            </el-form-item>
            </template>
            <div slot="otherContent" class="otherContent">
                <div class="tableAction">
	                <el-button slot="reference" type="primary" @click="createActivateCode()">创建卡密</el-button>
                </div>
            </div>
        </normal-table>
    </div>
</template>

<script>
import NormalTable from "@/components/NormalTable/index.vue";
import {getCookie} from "@/utils/auth";

export default {
    data() {
        return {
            total: 0,
            otherMap: {totalAccount:0},
            dateRange: [],
            form: {
                status: ''
            },
            url: "/admin/activationCode/list",
            tableAction: {
                label: "操作",
                prop: "handle",
                align: "center",
                formatter: ({_id, status}) => {
                    return (
                        <div class="action">
	                        ----
                        </div>
                    );
                },
            },
            selectList: [],
	        
	        payType: 'wechatPay',
	        chargeAmount: '',
	        chargeAmountCoin:"",
	        chargeQrCode: '',
	        picturePrice: 0,
	        articlePrice: 0,
	        coinPrice: 10,
        };
    },

    components: {
        NormalTable,
	    VueQr: () => import('vue-qr')
    },

    computed: {},

    mounted() {
	    this.$http.post('/client/common/getPublicConfig', {}).then(res => {
		    if (res.code === 0) {
			    res.data.list.forEach(item => {
				    if (item.key === 'picturePrice') {
					    this.picturePrice = item.value
				    } else if (item.key === 'articlePrice') {
					    this.articlePrice = item.value
				    } else if (item.key === 'coinPrice') {
					    this.coinPrice = item.value
				    }
			    })
		    }
	    })
    },

    watch: {
	    chargeAmount(val) {
		    this.changeAmount()
	    },
    },

    methods: {
	    createActivateCode() {
		    //弹窗输入添加数量
		    this.$prompt('请输入添加数量', '提示', {
			    confirmButtonText: '确定',
			    cancelButtonText: '取消',
			    inputPattern: /\d+/,
			    inputErrorMessage: '请输入数字'
		    }).then(({ value }) => {
			    const count = parseInt(value)
			    
			    //弹窗输入金币数量
					this.$prompt('请输入金币数量', '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    inputPattern: /\d+/,
				    inputErrorMessage: '请输入数字'
			    }).then(({ value }) => {
				    const amount = parseInt(value)
						this.$http.post('/admin/activationCode/create', {count,amount}).then(res => {
							this.$message({
								message: '添加成功',
								type: 'success'
							})
							setTimeout(() => {
								this.$refs.normalTable.getList()
							}, 1000)
						})
					}).catch(() => {
				    this.$message({
					    type: 'info',
					    message: '取消输入'
				    });
			    });
			    
		    }).catch(() => {
			    this.$message({
				    type: 'info',
				    message: '取消输入'
			    });
		    });
	    },
        // 获取接口数据
        loaded(data) {
            // console.log(data)
        },
        select(selection) {
            this.selectList = selection;
            console.log(selection);
        },
        selectAll(selection) {
            this.selectList = selection;
        },
        resetForm() {
            this.dateRange = []
            this.form = {}
        },
    },
};
</script>
<style lang="scss" scoped>
.el-form-item {
    flex-wrap: nowrap;
}
.order-list {
    .total {
        font-size: 14px;
        color: #333;
        text-align: right;
        padding-right: 30px;
    }
    .input-amount{
        display: inline-block;
        width: 100px;
    }
    .phone {
        width: 115px;
    }
    .day {
        width: 60px;
    }
}
.otherContent {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap;
    .tableAction {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: 5px;
        .el-button {
            margin-bottom: 5px;
        }
    }
    .allAccount {
        display: grid;
        place-items: end;
    }
}
::v-deep .el-message-box {
    max-width: 100% !important;
}
.el-message-box__message {
    max-width: 100% !important;
}
.action {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	.item {
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}
.charge-container {
	max-width: 500px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 1rem 0 2rem 0;
	border: solid 1px #ebeef5;
	margin: 2rem auto 1rem auto;
	border-radius: 1rem;
	.price-container {
		text-align: center;
		line-height: 2rem;
	}
	.balance {
		font-size: 1.5rem;
		font-weight: bold;
		display: flex;
		margin-bottom: 1rem;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.charge-input {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top:1rem;
		.charge-button {
			margin-top: .5rem;
			width: 5rem;
		}
	}
	.charge-type {
		display: flex;
		justify-content: space-around;
		.type-box {
			width: 3rem;
			text-align: center;
			cursor: pointer;
			padding: 0.3rem 0;
			box-sizing: border-box;
			border: solid 1px rgba(0, 0, 0, 0);
		}
		.active {
			border: solid 1px #409eff;
			border-radius: 5px;
		}
		img {
			width: 2rem;
		}
	}
}
</style>